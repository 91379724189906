/*
 * Custom SCSS Variables
 */

$color-gray: rgb(220, 220, 221);

// Include any default variable overrides here (though functions won't be available)

// Colors
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$dark: rgb(41, 43, 44);

// Fonts
$font-weight-lighter: 200;

// Navbar
$navbar-dark-active-color: $color-gray;

// Forms
$input-bg: $white;
$input-color: $black;

/*
 * Import Bootstrap styles
 * @see https://getbootstrap.com/docs/5.2/customize/optimize
 */

// Configuration
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';

/**
 * Custom Styles
 */

// Base

body,
html {
  overflow-x: hidden;
}

html {
  background: rgb(var(--theme-bg-rgb));
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body,
#__next {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

main {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

ul {
  padding: 0;
}

/**
 * Components
 */

// Images

.img-greyscale {
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: none;
  }
}

// Footer

.footer-email-signup-input {
  max-width: 150px;
}

// Phone Signup

.phone-signup-input {
  max-width: 150px;
}

// Event Calendar

.event-calendar {
  display: grid;
  column-gap: 0.25rem;
  grid-template-columns: repeat(7, minmax(115px, 1fr));
  overflow-x: auto;
}

.event-calendar-headings {
  font-weight: $font-weight-bold;
  margin-bottom: 0.25rem;
  text-align: center;
}

.calendar-day {
  position: relative;
  min-height: 450px;
}

.calendar-event-details {
  margin-bottom: 1rem;

  + .calendar-event-details {
    border-top: 1px solid $color-gray;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

// Assumes the presence of .stretched-link class on same element
.event-img-link {
  img {
    filter: grayscale(100%);
    transition: all 0.2s ease-in-out;
  }

  &:hover img {
    filter: none;
  }
}

@include media-breakpoint-up(md) {
  .event-calendar {
    grid-template-columns: repeat(7, 1fr);
    overflow-x: initial;
  }
}

// Technology Links

.technology-link svg {
  height: 2rem;
  width: 2rem;
}

/**
 * Pages
 */

// Single Event Page

.single-event-desc {
  border-top: 1px solid rgba($color-gray, 0.25);
}

/**
 * Utilities
 */

.border-gray {
  border-color: $color-gray !important;
}

.font-header {
  font-family: var(--theme-header-font);
}

.fs-7 {
  font-size: 0.85rem !important;
}

.fs-5 {
  font-size: 1.1rem !important;
}

.l-spacing-1 {
  letter-spacing: $spacer * 0.05 !important;
}

/*
* Themes
*/

// Base theme styles
.theme {
  background-attachment: fixed;
  background-image: var(--theme-body-bg-url);
  background-color: rgb(var(--theme-bg-rgb));
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(var(--theme-text-rgb));
  font-family: var(--theme-body-font);
  flex-grow: 1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--theme-header-font);
  }

  a {
    color: rgb(var(--theme-text-rgb));

    &:hover,
    &:focus {
      color: rgba(var(--theme-text-rgb), 0.5);
    }
  }

  .navbar {
    background-color: rgb(var(--theme-bg-rgb));
    border-bottom: 1px solid rgba(var(--bs-body-color-rgb), 0.4);
  }

  .footer {
    background-color: rgb(var(--theme-bg-rgb));
  }

  .btn-buy-now {
    background: rgba(var(--theme-text-rgb), 0.2);
    border: solid 1px rgba(var(--theme-bg-rgb), 0);
    border-radius: 0;
    color: rgba(var(--theme-text-rgb), 1);
    cursor: pointer;
    letter-spacing: 2px;
    padding: 0.75rem;
    text-transform: uppercase;

    &:hover {
      background: rgba(var(--theme-bg-rgb), 1);
      border: solid 1px rgba(var(--theme-text-rgb), 1);
      color: rgba(var(--theme-text-rgb), 1);
    }
  }

  .btn-reservation {
    background: rgba(var(--theme-text-rgb), 0);
    border: solid 1px rgba(var(--theme-text-rgb), 1);
    border-radius: 0;
    color: rgb(var(--theme-text-rgb));
    cursor: pointer;
    letter-spacing: 2px;
    padding: 0.75rem;
    text-transform: uppercase;

    &:hover {
      background: rgba(var(--theme-text-rgb), 1);
      color: rgba(var(--theme-bg-rgb), 1);
    }
  }
}

/**
 * Florida Theme
 */
.theme-florida {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $font-weight-bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .listing {
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray;
      margin-bottom: 4rem;
      padding-bottom: 4rem;
    }
  }

  .listing-date {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 2rem 0;
    padding: 0.5rem 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .listing-date-item {
    &:not(:last-child) {
      margin-right: 0.25rem;
    }
    &:last-child {
      font-weight: $font-weight-bold;
    }
  }

  .listing-date-item--day-name {
    font-size: 0.85rem;
  }

  .listing-date-item--month-day {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(md) {
    .listing-header {
      align-items: center;
      display: flex;
      margin-right: 2rem;
    }

    .listing-date {
      border: 1px solid $color-gray;
      flex-direction: column;
      flex-shrink: 0;
      margin: 0 1rem 0 0;
      text-align: center;
    }

    .listing-date-item {
      display: block;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .listing-details {
    font-weight: $font-weight-lighter;
    letter-spacing: 2px;
    text-transform: uppercase;

    p {
      margin-bottom: 0.25rem;
    }
  }

  // Single Event Page

  .single-event-artists {
    font-weight: $font-weight-lighter;
    letter-spacing: 2px;
    text-transform: uppercase;

    p {
      margin-bottom: 0.25rem;
    }
  }

  // Dark Theme
  &.theme-dark {
    .form-floating label {
      color: rgb(var(--theme-bg-rgb));
    }
  }

  // Footer
  .footer-logo {
    max-height: 100px;
  }
}

/**
 * DC Theme
 */
.theme-dc {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }

  .listing {
    &:not(:last-child) {
      margin-bottom: 8rem;
    }
  }

  .listing-date {
    display: block;
    font-size: 0.85rem;
    margin: 0 0 0.5rem 0;
  }

  .listing-date-item {
    &:not(:last-child) {
      &::after {
        content: '/';
        margin: 0 0.25rem;
      }
    }
  }

  .listing-details {
    p {
      margin-bottom: 0.25rem;
    }
  }

  // Single Event Page

  .single-event-artists {
    p {
      margin-bottom: 0.25rem;
    }
  }

  // Dark theme
  &.theme-dark {
    .form-floating label {
      color: rgb(var(--theme-bg-rgb));
    }
  }

  // Navbar overrides
  @include media-breakpoint-down(md) {
    .navbar-expand-md {
      .navbar-collapse {
        background-color: rgb(var(--theme-bg-rgb));
        display: flex;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.4s;
        visibility: hidden;
        width: 100%;
        width: 100%;
        z-index: -1;

        &.show {
          min-height: 100vh;
          opacity: 1;
          visibility: visible;
          z-index: 9;
        }
      }
      .navbar-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .nav-link {
        font-size: 1.5rem;
      }
      .navbar-toggler,
      .navbar-brand {
        z-index: 99;
      }
    }
  }

  // Footer
  .footer-logo {
    max-height: 45px;
  }
}

// Theme overrides
.theme-no-bg {
  background-image: none !important;
}
